import { Parallax } from "react-parallax"
import home from "../img/image1.png"

const insideStyles = {
    borderRadius:'50px',
    background: "#333",
    padding: 10,
    position: "absolute",
    bottom: '3%',
    left: '60%'
};

const ApresentacaoParallax = () => (
    <Parallax bgImageStyle={{maxHeight: '100%'}} bgImage={home} strength={200}>
        <div id='apresentacao-parallax' style={{ height: '100vh'}}>
            <div style={insideStyles}>
                <div className="text-box-n1">Dra. Isabela Augusta <br /> de Castro Lemos</div>
                <div className="text-box-n2">Endocrinologia e Metabologia <br /> CRM-MG 64742 <br /> RQE 45581</div>
            </div>
        </div>
    </Parallax>
)

export default ApresentacaoParallax