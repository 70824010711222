import React from "react"

const QuandoProcurar = () => {
    
    const rolarParaSecao = (secaoId) => {
        const elementoSecao = document.getElementById(secaoId)
        if (elementoSecao) elementoSecao.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <section id='quando-procurar'>
            <h1 className="text-upper">Quem é o Endocrinologista?</h1>
            <p className="lh-base fs-2 text-justify text-break">O endocrinologista é o médico que faz uma avaliação hormonal do paciente, bem como trata de doenças relacionadas com o excesso ou a falta de hormônios. Por sua vez, os hormônios são como mensageiros dentro do corpo humano e são fundamentais para o funcionamento dos órgãos. Os mais conhecidos, por exemplo, são os hormônios da tireoide, do crescimento, a testosterona, o estrogênio e o cortisol, mas, existem muitos outros menos conhecidos, tais como a aldosterona, a prolactina, o paratormônio e outros.</p>
            <p>&nbsp;</p>
            <h1 className="text-upper">Quando procurar um Endocrinologista?</h1>
            <p className="lh-base fs-2 text-justify text-break">Pessoas que possuem um diagnóstico de doença endocrinológica necessitam de acompanhamento regular, por isso há necessidade de zelo com doenças como diabetes mellitus, hipotireoidismo e osteoporose. Caso não possua esse diagnóstico, mas observou algum sintomas novo, como excesso de pelos, agitação, taquicardia, insônia, sonolência excessiva (ou cansaço além do normal), secreção de leite em mulheres que não estão amamentando, ciclo menstrual irregular, aumento de mamas em meninos, alterações do crescimento ou puberdade em crianças, é aconselhado fazer uma avaliação especializada.</p> 
            <p>&nbsp;</p>
            <h1 className="text-upper">Avaliação</h1>
            <p className="lh-base fs-2 text-justify text-break">Caso suspeite ou apresente os sintomas mencionados anteriormente, estou disponível para ajudar. Basta agendar uma <button className="btn-agendar" style={{border: 'none', borderRadius: '10px', padding: '5px'}} onClick={() => rolarParaSecao('agendamentos-parallax')}>consulta</button>!</p>
            <p>&nbsp;</p>
        </section>
    )
}

export default QuandoProcurar
