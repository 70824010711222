import React from "react"
import { FaQuoteLeft } from "react-icons/fa";

const Cards = ({ list }) => {
    const comentario = list[0]
    const nome = list[1]
    return (
        <div className="card mb-3 cards-depoimentos">
            <div className="row g-0 align-items-center">
                <div className="col-md-2 p-2">
                    <FaQuoteLeft size={40}/>
                </div>
                <div className="col-md-10 align-items-center">
                    <div className="card-body ">
                        <h5 className="card-title">{comentario}</h5>
                        <div className="card-text text-end m-top-10">{nome}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cards