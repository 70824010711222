import React from "react"
import { FaPhone, FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

const Clinicas = ({ nome, telefone, whatsapp, email, endereco }) => {
    const title = {
        fontSize: '1.25rem',
        color: 'var(--t1-8)',
        fontWeight: '800',
        textTransform: 'uppercase',
        textAlign: 'center'
    }

    return (
        <>
            <div style={title}>{nome}</div>
            <div className="container">
                <div className="row">
                    <div className="col-1"><FaPhone /></div>
                    <div className="col">{telefone}</div>
                </div>
                <div className="row">
                    <div className="col-1"><FaWhatsapp /></div>
                    <div className="col">{whatsapp}</div>
                </div>
                <div className="row">
                    <div className="col-1"><MdAlternateEmail /></div>
                    <div className="col">{email}</div>
                </div>
                <div className="row">
                    <div className="col-1"><FaLocationDot /></div>
                    <div className="col">{endereco}</div>
                </div>
            </div>
        </>
    )
}

export default Clinicas

