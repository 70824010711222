import { Parallax } from "react-parallax"
import Depoimentos from '../img/depoimentos.png'

const insideStyles = {
    borderRadius:'50px',
    background: "#333",
    margin: '20px',
    padding: '10',
    position: "absolute",
    width: '400px',
    bottom: '0',
    right: '0',
};

const DepoimentosParallax = () => (

    <Parallax bgImageStyle={{ maxWidht: '80%' }} bgImage={Depoimentos} strength={200}>
        <div id='depoimentos-parallax' style={{ height: '40vh' }}>
            <div style={insideStyles}>
                <div className="text-box-n1">Depoimento de Pacientes e Familiares</div>
            </div>
        </div>
    </Parallax>
)

export default DepoimentosParallax