import React from "react"
import WhatsApp from '../components/WhatsApp'
import Clinicas from "../components/DadosClinicas"


const Agendamentos = () => {

    return (
        <section id='agendamentos'>
            <h1>Agendar Consultas</h1>
            <p className="lh-base fs-2 text-justify text-break">Caso precise de assistência médica ou queira marcar uma consulta, sinta-se à vontade para entrar em contato conosco pelo WhatsApp:</p>
            <WhatsApp />

            <p>&nbsp;</p>
            <p className="lh-base fs-2 text-justify text-break">Caso prefira, você também pode nos encontrar nas seguintes localidades:</p>

            <div className="container">
                <div className="row mb-5  align-items-center">
                    <div className="col-sm">
                        <Clinicas
                            nome='Life Instituto de Medicina Avançada'
                            telefone='(31) 3739-5500'
                            whatsapp='(31) 97199-5500'
                            email='lifeinstitutodemedicina@gmail.com'
                            endereco='Rua Coronel Jose Joaquim Queiros Junior, Nº 468 - Campo Alegre, Conselheiro Lafaiete, Minas Gerais' />
                    </div>
                    <div className="col-sm">
                        <iframe title='Life Instituto de Medicina Avançada' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.358481930158!2d-43.795172687395414!3d-20.65498966121743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa16020460839ff%3A0x67e2248cd6cd1282!2sR.%20Cel.%20Jos%C3%A9%20Joaquim%20Queir%C3%B3s%20J%C3%BAnior%2C%20468%20-%20Santa%20Efigenia%2C%20Conselheiro%20Lafaiete%20-%20MG%2C%2036400-000!5e0!3m2!1spt-BR!2sbr!4v1704033231444!5m2!1spt-BR!2sbr" width="350" height="300" style={{ border: 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-sm">
                        <Clinicas
                            nome='Espaço Vita'
                            telefone='(31) 3749-7450'
                            whatsapp='(31) 98771-0168'
                            email='contato@meuespacovita.com.br'
                            endereco='Av Frederico Varnhagem , 331 - Pioneiros - Ouro Branco, Minas Gerais' />
                    </div>
                    <div className="col-sm">
                        <iframe title="Espaco Vita" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3736.926261613495!2d-43.71370258739843!3d-20.509248856531634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-BR!2sbr!4v1704033129101!5m2!1spt-BR!2sbr" width="350" height="300" style={{ border: 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    )
}

export default Agendamentos