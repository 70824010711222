import React from "react"
import Cards from "../components/CardsDepoimentos";

const Depoimentos = () => {

    const depoimentos = [
        { autor: 'Clarisse Nasario Gomes', texto: 'Não tenho nada a reclamar, desde o atendimento da recepção até a Dra. Isabela Lemos foi extraordinário. Amei' },
        { autor: 'Katilene', texto: 'Além de excelente profissional, muito humana! Recomendo!' },
        { autor: 'Maria Helena Moraes Pereira', texto: '1º- saber ouvir as queixas 2º- expressar o interesse em ajudar 3º - indicar o melhor tratamento e te tranquilizar sobre o resultado.' },
        { autor: 'Maria Gorete Aparecida Vasconcelos Paula', texto: 'Dra Isabela é muito educada, calma, paciente , comprometida e eficiente no atendimento e diagnóstico.' },
        { autor: 'Patrícia de Fátima Ribeiro', texto: 'Saí da consulta tão bem, incentivo em primeiro lugar, medicação coadjuvante, super recomendo nota 1000' },
        { autor: 'Elisandra Diene', texto: 'A doutora é muito atenciosa e me explicou sou bem o que é a tireoide de Hashimoto.' },
        { autor: 'Leonora', texto: 'Me senti segura durante a consulta e ao longo do tratamento. O tratamento esta sendo eficaz. A doutora Isabela além de tratar o problema endocrinológico sempre me avalia integralmente.' },
        { autor: 'Ilma', texto: 'Primeira vez que consultei com ela . Gostei muito. Ela é muito atenciosa, competente.' },
        { autor: 'Daniela Marinho de Sousa Resende', texto: 'Tudo mto bem falado e explicado. Consulta feita com carinho, atenção e cuidado. Só agradecer' },
        { autor: 'Conceição', texto: 'Atendimento excelente! Atenciosa, cuidadosa no trato e nas explicações. Humana!!!' },
        { autor: 'Gustavo', texto: 'Dra Isabela supera no atendimento, atenta a ouvir e cuidar com explicações lógicas e que deixam qualquer tratamento ou acompanhamento, mais fácil e seguro.' },
        { autor: 'Wagner Azevedo', texto: 'Excelente médica, adorei a consulta, nunca fiquei mais que meia hora num consultório. Consulta dela durou quase duas horas' },
        { autor: 'Silvana', texto: 'Excelente. Nota 10 em todos os quesitos. Atenciosa, explica tudo. Só de conversar já me senti motivada.' },
        { autor: 'Andreza', texto: 'Muito atenciosa, primeira vez que chego a um consultório e sou atendida dentro do horário.Explica todos os detalhes com muito cuidado, eu gostei muito é super indico.' },
        { autor: 'Dyne Kelle de Paiva Fonseca', texto: 'Médica completa! Extremamente atenciosa! Completa! Não atende só a parte endócrino. Ela vai totalmente além!' },
        { autor: 'José Henriques Júnior', texto: 'Excelente consulta, pois, superou as minhas expectativas.' },
        { autor: 'Aline de Castro Pereira', texto: 'Excelente profissional. Muito atenciosa e educada.' },
        { autor: 'Mara Luiza', texto: 'Excelente profissional , muito atenciosa, super recomendo!' },
        { autor: 'Camilla A Rodrigues', texto: 'O atendimento foi ótimo, a Dra explicou todas as minhas dúvidas, ela é muito atenciosa.' },
        { autor: 'Gabriela Alves Silva', texto: 'Eu adorei o atendimento da Dra. Isabela, ela é muito atenciosa, simpática, explica de maneira clara, além de me ajudar a chegar no meu objetivo.' },
        { autor: 'Isabela Fernandes', texto: 'Excelente a consulta, tirou todas minhas dúvidas, e foi super atenciosa' },
        { autor: 'Paula Matos', texto: 'Excelente médica! Super atenciosa, explica tudo muito bem! Me passou muita segurança.' },
        { autor: 'Amanda', texto: 'Excelente profissional! Objetiva, cuidadosa. Me explicou coisas q não tinha o conhecimento! Foi uma consulta mto detalhada, longa, o consultório é super aconchegante.' },
        { autor: 'Daniele', texto: 'Estou encantada! Excelente profissional! Muito atenciosa!' },
        { autor: 'Tânia Teixeira', texto: 'Receptividade e boa conduta durante a consulta. Transmite segurança, e demonstra calmamente seu conhecimento!' },
        { autor: 'Vanessa Tavares Brum de Rezende', texto: 'Excelente atendimento , pessoas educadas e atenciosas' },
        { autor: 'Renata Assis Pereira', texto: 'Excelente profissional, muito acolhedora e educada.' },
        { autor: 'Fernanda Silva', texto: 'Muito anteciosa, atenta a cada detalhe, acolhedora, ótima profissional. Super pontual, o consultório conta com uma excelente estrutura' },
        { autor: 'Juliana Rodrigues Reis', texto: 'Ótimo atendimento, super atenciosa e explicou tudo muito bem! Excelente profissional.' },
        { autor: 'Kilma Almeida', texto: 'Excelente médica! Atenciosa, detalhista, atualizada. Demonstra domínio de conteúdo. Recomendo!!' },
        { autor: 'Greicilene', texto: 'Excelente médica, e super atenciosa. Explica tudo sem deixar nenhuma dúvida' },
        { autor: 'Sônia', texto: 'Excelente médica!! Explica tudo com muito detalhe! Adoro!!' },
        { autor: 'Paciente', texto: 'Consulta espetacular, atenciosa e experiente. Recomendo.' },
        { autor: 'Paula Daiane Nascimento', texto: 'Excelente profissional, atenciosa, explica cada detalhe. Amei o atendimento.' },
        { autor: 'Paciente', texto: 'Excelente profissional! É atenciosa e valoriza seus pacientes. Proporciona um atendimento exemplar que assegura simpatia e confiança no seu trabalho.' },
        { autor: 'Maria do Carmo', texto: 'Médica muito atenciosa, pontual. Esclarece todas as dúvidas.' },
        { autor: 'Cristina Santos', texto: 'Excelente profissional, competente e muito humana.' },
        { autor: 'Angela', texto: 'Muito atenciosa, tira as suas dúvidas, excelente profissional!' },
        { autor: 'Vânia Alexandra Benício Domingos', texto: 'Uma excelente médica explica muito bem , muito comunicativa , calma , trata paciente com muita dedicação e bondosa e carinhosa' },
        { autor: 'Mariana Almada', texto: 'Excelente profissional, muito atenciosa no atendimento e assertiva no tratamento.' },
        { autor: 'João Paulo', texto: 'Ótima profissional médica. Ótimo consultório e equipamentos.' },
        { autor: 'Sirlene', texto: 'Adorei a consulta, explica muito bem, tira as dúvidas, atenciosa. Muito simpática. Recomendo' },
        { autor: 'Bruno Patrício Lins', texto: 'Muito atenciosa e competente, excelente profissional.' },
        { autor: 'Regiane', texto: 'Foi minha primeira consulta com ela e eu gostei muito,muita atenciosa, carismática,e ótima profissional' },
        { autor: 'Camila Guimarães', texto: 'Dra extremamente simpática, esclarece bem todas as dúvidas, adorei a consulta. Será minha Endocrino aqui na cidade.' },
        { autor: 'Andréa Fátima da Silva', texto: 'Melhor impossível ela é excelente dá muita atenção' },
        { autor: 'Ilmara', texto: 'Tudo certo, consulta muito boa, atendeu as expectativas !' },
        { autor: 'Luciana Reis', texto: 'Muito atenciosa e conhecedora do assunto , aborda não somente a parte clínica mas também o emocional. Pois no meu caso é o que predomina.' },
        { autor: 'Rosilene Martins', texto: 'Mto atenciosa, passa paz e tranquilidade pro paciente. E é mto linda.' },
        { autor: 'Lucas Santhiago', texto: 'Excelente profissional. Atenciosa e explica de forma fácil de entender.' },
        { autor: 'Janaína', texto: 'Profissional excelente, atenciosa, explica detalhadamente tudo sem pressa, pontual.' },
        { autor: 'Emanuele Aparecida Lopes', texto: 'Gostei muito da consulta. Dra Isabela muito atenciosa e atenta as nossas necessidades.' },
        { autor: 'Humberto Alves Vieira', texto: 'Fiquei muito contente. Espero que eu consiga corresponder' },
        { autor: 'Viviane Silva Araújo Rocha', texto: 'Excelente profissional, muito atenciosa e transmite segurança ao paciente.' },
        { autor: 'Jéssica', texto: 'A consulta foi excelente, a Dr. Isabela é muito atenciosa, explica muito bem e tira todas as dúvidas.' },
        { autor: 'William Silva', texto: 'Excelente profissional, atensiosa e preocupada em explicar tudo da melhor forma possível, consulta muito completa. Superou expectativas' },
        { autor: 'Paciente', texto: 'Adorei o atendimento, foi muito atenciosa e paciente explica muito bem!' },
        { autor: 'Suely', texto: 'A Dra Isabela é muito simpática e atenciosa. Tudo bem explicado . Recomendo muito. Excelente profissional.' },
        { autor: 'Dalila', texto: 'Dra.Isabela é maravilhosa,atenciosa ,explica todas as dúvidas,e tem opção de tratamento' },
        { autor: 'Janaína Campos', texto: 'Excelente consulta, pontual, atenciosa, passa ótimas explicações. Levei minha filha e ficamos muito satisfeitas.' },
        { autor: 'Claudia calixto', texto: 'Indico a profissional para todas as pessoas que precisarem . Dra Izabela endocrinologista nota 1000' },
        { autor: 'Caio Pereira Andrade', texto: 'Excelente profissional! Fui muito bem atendido, estou muito satisfeito com a consulta. Muito detalhista, muito atenciosa! Recomendadíssima!' },
        { autor: 'Janaine', texto: 'Excelente profissional , atenciosa, dedicada, educada, competente, pontual,amplo conhecimento, simpática.' },
        { autor: 'Samara', texto: 'Ótimo atendimento. Super recomendo. Explica direitinho o tratamento, fala de tudo que faz você tá com metabolismo lento.. nota 10' },
        { autor: 'Mara Resende', texto: 'Excelente profissional, super atenciosa e pontual. A clínica com um ambiente limpo e agradável. Parabéns!' },
        { autor: 'Claudio', texto: 'Muito atenciosa, explica tudo bem detalhado. Muito satisfeito com a primeira consulta. Tratamento foi muito bem detalhado.' },
        { autor: 'Maria José', texto: 'Um espetáculo de consulta, o atendimento perfeito! Explicação ótima! E mto conhecimento no q faz! Parabéns !' },
        { autor: 'Celita Peixoto Alves', texto: 'Apenas mais interatividade entre a médica e a nutricionista indicada pela clínica. O atendimento é excelente e a médica melhor ainda. Obrigada.' },
        { autor: 'Jéssica', texto: 'Dr Isabela é super atenciosa, retira todas as dúvidas melhor endocrina com certeza! As minhas expectativas foram atendidas!' },
        { autor: 'Francisco Lodron', texto: 'Minha opinião é que ela é uma excelente profissional,consulta detalhada explica todos os detalhes e todas as minhas dúvidas' },
        { autor: 'Alessandra Madalena França Antunes', texto: 'Ótima profissional muito anteciosa explica tudo direitinho gostei muito e eu recomendo tá de parabéns' },
        { autor: 'Adriana Cristina B Silva', texto: 'Dr. Isabela é muito atenciosa, pontual, explica tido cpm detalhes. Consultei com ela e ainda tive direito ao atendimento com nutricionista! Excelente profissional!' },
        { autor: 'Paciente', texto: 'Muito atenciosa e explica com calma as informações. Tira todas as dúvidas. Atendimento pontual e esclarecedor.' },
        { autor: 'Sabrina Neto Lana Peixoto', texto: 'Excelente profissional. Muito atenciosa! Esclarece todas as dúvidas com muita calma. Estou muito satisfeita com o tratamento.' },
        { autor: 'Edenilda', texto: 'Ótima profissional explica tudo com calma aconselha encoraja a prosseguir gostei muito da consulta e recomendo' },
        { autor: 'Laura', texto: 'A Dra. Isabela é uam excelente médica, atenciosa, simpática, esclareceu todas as minhas dúvidas! Recomendo muito!' },
        { autor: 'Jefferson Coelho Ferreira', texto: 'Excelente médica, explica tudo detalhadamente, muito atenciosa e extremamente profissional. Recomendo a todos que buscam um especialista nessa área.' },
        { autor: 'Rafaela', texto: 'Muito atenciosa, ótima profissional não atende com muita correria, examina e explica todos os detalhes' },
        { autor: 'Ana Paula Miranda', texto: 'Excelente médica . Muito atenciosa. Super competente. Recomendo a todos que procuram por uma endocrinologista .' },
        { autor: 'Paciente', texto: 'Uma profissional excelente, muito atenciosa e simpática! A Dra. Isabela é uma ótima profissional, estou ansiosa para dar continuidade ao tratamento.' },
        { autor: 'Paciente', texto: 'Excelente profissional, muito didática e atenciosa. Conseguiu responder a todas minhas dúvidas de forma simples e eficaz.' },
        { autor: 'Susana de Freitas Alves Francisco', texto: 'Excelente a consulta, ela explica tudo detalhadamente, pede muitos exames, Excelente profissional, estou muito satisfeita.' },
        { autor: 'Enzo Amorim Nogueira', texto: 'Procurei Dra. Isabela por indicação de um outro profissional super competente e fiquei muito satisfeito. Além da competência, Dra Isabela é muito atenciosa!' },
        { autor: 'Carmem Célia Gomes', texto: 'Consulta ótima. Médica muito atenciosa. Responde a todas as dúvidas com atenção e simpatia. Recomendo a profissional.' },
        { autor: 'Cláudia Lúcia Maciel Buchemi', texto: 'Profissional mto competente e atenciosa! Não há atraso significativo na consulta.É confiável e passa mta segurança. Recomendo a todos.' },
        { autor: 'Erica Helena vieira', texto: 'Fui com minha filha e ambas gostamos bastante. Clara , objetiva e pontual. Sem contar que foi atenciosa e empática com nossas queixas.' },
        { autor: 'Camila Rezende', texto: 'Primeira consulta e foi excelente! Ótima profissional. Senti total confiança e certeza de que terei sucesso no tratamento proposto.' },
        { autor: 'Marina da Silva Maia Matos', texto: 'Excelente profissional. Explica com detalhes. Muito atenciosa. Pontual.Atendimento muito bom.Recomendo.' },
        { autor: 'Adriane R C Souza', texto: 'Muito atenciosa! Excelente consulta, fiquei muito satisfeita...Foi a minha primeira consulta com ela...eu super recomendo' },
        { autor: 'Paciente', texto: 'Excelente profissional! Muito atenciosa. Muito atual e capaz. Acredito que estou bem cuidada com o tratamento proposto.' },
        { autor: 'Lucene Marques', texto: 'Atendimento espetacular,explica suas dúvidas,tive resultados em um mês de tratamento...exelente profissional!' },
        { autor: 'Paciente', texto: 'Ótima consulta. Atenciosa. Gentil. Simpática. Sanou todas as minhas dúvidas. Recomendo sem dúvidas.' },
        { autor: 'Delinda', texto: 'Excelente atendimento, muito atenciosa, dá a devida atenção ao que você precisa, explica de uma forma que você entende, recomendo!' },
        { autor: 'Flávia', texto: 'Profissional competente , muito séria, atenciosa e detalhista . Se preocupa com o paciente, não medindo esforços para esclarecer qualquer dúvida.' },
        { autor: 'Josiane Resende e vera Lúcia Gama Resende', texto: 'Excelente profissional, atenciosa, olha os mínimos detalhes, já saí do consultório indicando os serviços dela , a melhor.' },
        { autor: 'Mylena', texto: 'Ótima profissional e super competente! Explica muito bem! Muito dedicada e legal! Gostei bastante da consulta!' },
        { autor: 'Maria Tereza Vaz', texto: 'Muito atenciosa, muito competente, disposta a ajudar , recomendo a profissional! Explicou todo o tratamento e ainda me deu ótimas sugestões' },
        { autor: 'Simone', texto: 'Isabela é super atenciosa, de uma delicadeza ímpar mesmos nos “puxões de orelha”, muito atualizada e segura diante de suas propostas.' },
        { autor: 'Luana Felipe de Oliveira', texto: 'Foi uma excelente consulta,atenciosa, explicação bem detalhada , excelente médica como pessoa e profissional tbm ,super paciente ,sabe escutar o paciente e super indico .' },
        { autor: 'Gustavo Cabral', texto: 'Excelente médica, tem empatia pelo paciente e muito atenciosa. Explica em detalhes o tratamento. Recomendo.' },
        { autor: 'Miria Cristina Vieira Ângela', texto: 'adorei ela muito anteciosa mi explicou cada detalhe uma proficinal nota 1000se tô pro adicional fosse assim seria ótimo super indico ele.' },
        { autor: 'José Adilson de Jesus Conceição', texto: 'Excelente profissional, mim passou bastante confiança por ser a primeira consulta volte para casa bem animado .' },
        { autor: 'Paciente', texto: 'A Dra. Isabela é uma excelente médica, ela é atenciosa e muito atualizada. Emagreci 10 kg com a ajuda dela, recomendo!!!' },
        { autor: 'Georgiane Conceição do Carmo', texto: 'Eu cheguei muito desanimada na primeira consulta mas depois de 2 meses já tive bons resultados!Estou muito feliz com o resultado' },
        { autor: 'Ciomara Corrêa', texto: 'Extremamente atenciosa e pontual. Profissional detalhista e muito boa. Sempre antenada com o que há demais novo na medicina.' },
        { autor: 'Wagner Silva', texto: 'Super recomendo. Medica sempre pronta para esclarecer minhas dúvidas, além de muito simpática. Já sou paciente há uns 3 anos.' },
        { autor: 'Juliana Silva', texto: 'Excelente atendimento, médica atenciosa e atualizada nos diagnósticos e tratamentos. Responde nossas dúvidas com calma e competência' },
        { autor: 'Lidiane Soares', texto: 'Excelente profissional extremamente atenciosa e competente. Escuta o paciente, tira todas as dúvidas explicando com clareza.' },
        { autor: 'Grasiele Jaquelina', texto: 'Ótima profissional onde tenho obtido ótimos resultados , atencioso com seus pacientes.Sempre incentiva a não parar com o nosso objetivo.Super indico.' },
        { autor: 'Cassia Maria Vieira Cunha', texto: 'Dra Isabela excelente profissional, super atenciosa e dedicada . Atende seus pacientes de forma humana .' },
        { autor: 'Isabella', texto: 'Super atenciosa e ótima profissional. Explicou tudo direitinho e me deixou super tranquila quanto a minha tireoide.' },
        { autor: 'Marina', texto: 'Melhor Endocrinologista! Muito atenciosa, atualizada e preocupada em sempre fazer o melhor para seu paciente! Amei a consulta!' },
        { autor: 'Diogo Cabral', texto: 'Médica consegue entender a rotina dos pacientes e aplicar a melhor terapêutica para sua melhora. Possui clareza em suas explicações que tomar fácil o entendimento da consulta.' },
        { autor: 'Daniela Miranda', texto: 'Excelente profissional, atenciosa e dedicada! A melhor endocrinologista de Lafaiete e região! Parabéns e obrigada!' },
        { autor: 'Paula', texto: 'Excelente médica! Ótima consulta, como sempre tirou todas as minhas dúvidas, explicou todo o tratamento e a importância dele.' },
    ]
    
    const depoimentosGrandes = [
        { autor: 'Maria Fernanda', texto: 'Dra Isabela Lemos é muito atenciosa na consulta. Tira as dúvidas , educada e clara nas explicações .Tem um contato direto com o paciente mesmo após a consulta dando um suporte para o uso devido do tratamento. Uma médica diferenciada que trás segurança para o paciente.' },
        { autor: 'Edriane Xavier', texto: 'Fiquei bastante satisfeito com o atendimento. A Dra Isabela foi excelente profissional, bastante dedicada, atenciosa, tirou todas as minhas dúvidas, foi bastante precisa com as explicações de forma bem clara e compreensível.' },
        { autor: 'Liliane Pereira', texto: 'Muito atenciosa, consulta detalhada e completa. Se colocou disponível, passou celular, se preocupou em não onerar a receita, nos deu amostra grátis. Sentimos muita confiança no seu trabalho.' },
        { autor: 'Ricardo Alves Antunes', texto: 'Bom atendimento, atenciosa com as questões a ela levadas, não deixa dúvidas quanto ao tratamento e medicação a ser usada. Atendimento no horário marcado, local agradável para espera bem como o consultório.' },
        { autor: 'Katya Ribeiro', texto: 'A clinica é muito bem montada, as atentendes educadas e atenciosas. Mas o mais importante foi a consulta e o atendimento médico. Gostei muito e saí mais segura para fazer o tratamento. Eu indico , a dra. Isabela é muito simpática e profissional.' },
        { autor: 'Samantha Silva Rezende', texto: 'Achei maravilhoso, ela tratou a questão de forma extremamente atenciosa e tranquila, não fez nenhuma imposição, nós ajudando a buscar o melhor caminho a se seguir de acordo com nossa rotina e opiniões. Além do mais, eu como uma paciente pré - adolescente me senti completamente incluída na consulta, eu respondia as perguntas enquanto minha mãe acompanhava, não o contrário como ocorre muitas das vezes. Eu e meus pais ficamos eternamente gratos. Super recomendamos' },
        { autor: 'Luciana Siqueira', texto: 'Dra. Isabela e muito atenciosa, explicou todas as minhas dúvidas e do meu esposo, nós transmitiu muita segurança e conhecimento quando nos passou o diagnóstico. Uma ótima profissional!!' },
        { autor: 'Rosely', texto: 'Isabela é uma pessoa maravilhosa, agradável, comprometida com o que faz, sua consulta é sem pressa, retirando nossas dúvidas, orientando, com toda paciência para que possamos sair do consultório com um tratamento de excelência.' },
        { autor: 'Viviane Guimarães', texto: 'Isabela é muito atenciosa, simpática e competente. Me deixou completamente à vontade durante a consulta, explicou detalhadamente os resultados dos exames que apresentei, as possibilidades de tratamento e os resultados esperados. Estou muito satisfeita.' },
        { autor: 'Adriana Lourenço', texto: 'Excelente profissional, atenciosa, explica de forma clara, a consulta não é corrida, sana todas nossas dúvidas, se preocupa com o cliente. Enfim, profissionalissima é a palavra que a descreve. Recomendo de olhos fechados, amei a consulta!!!' },
        { autor: 'Joseana Dias', texto: 'Tirou um peso das minhas costas! Excelente doutora. Consulta completa em 16 anos de diabética tipo 1 nunca tive uma explicação tão clara e detalhada sobre o tratamento. Agradeço muito a doutora pela atenção.' },
        { autor: 'Elizabeth da Cruz Bavuso Silva', texto: 'Dra Isabela é uma excelente profissional. Uma endocrinologista muito competente e atenciosa. Desde a primeira consulta com ela tenho tido mais saúde e minha diabetes tem estado controlada, às vezes a glicose cai um pouco, mas estou aprendendo a me conhecer melhor e me controlar.' },

    ]
    const gerarNumerosRandomicos = (min = 0, max = 130) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const gerarDepoimento = () =>{
        const tamanho = depoimentos.length === 0 ? 0 : depoimentosGrandes.length - 1
        const numero = gerarNumerosRandomicos(0, tamanho)
        return [depoimentos[numero].texto,depoimentos[numero].autor]
    }

    const gerarDepoimentoGrandes = () =>{
        const tamanho = depoimentosGrandes.length === 0 ? 0 : depoimentosGrandes.length - 1
        const numero = gerarNumerosRandomicos(0, tamanho)
        return [depoimentosGrandes[numero].texto, depoimentosGrandes[numero].autor]
    }

    const rolarParaSecao = (secaoId) => {
        const elementoSecao = document.getElementById(secaoId)
        if (elementoSecao) elementoSecao.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <section id='depoimentos'>
            <h1>Depoimentos de Familiares e Pacientes</h1>
            <p className="lh-base fs-2 text-justify text-break">Veja abaixo alguns depoimentos de nossos pacientes e familiares:</p>
            <div className="container text-center">
                <div className="row">
                    <div className="col-sm-4"><Cards list={gerarDepoimento()} /></div>
                    <div className="col-sm-4"><Cards list={gerarDepoimento()} /></div>
                    <div className="col-sm-4"><Cards list={gerarDepoimento()} /></div>
                </div>
                <div className="row">
                    <div className="col-sm-5"><Cards list={gerarDepoimento()} /></div>
                    <div className="col-sm-7"><Cards list={gerarDepoimentoGrandes()} /></div>
                </div>
            </div>
            <p>&nbsp;</p>
            <h1 className="text-upper">Consulta</h1>
            <p className="lh-base fs-2 text-center text-break">O que está esperando? Agende já uma <button className="btn-agendar" style={{border: 'none', borderRadius: '10px', padding: '5px'}} onClick={() => rolarParaSecao('agendamentos-parallax')}>consulta</button>!</p>
            <p>&nbsp;</p>
        </section>
    )
}

export default Depoimentos