import React from "react"
import CardsAtuacao from "../components/CardsAtuacao"

import diabetes from '../icones/diabetes.png'
import obesidade from '../icones/obesidade.png'
import tireoide from '../icones/tireoide.png'
import osteoporose from '../icones/osteoporose.png'
import dislipidemia from '../icones/colesterol.png'
import outros from '../icones/outros.png'

const AreaAtuacao = () => {
    const diabetesText = 'A diabetes mellitus é uma doença que apresenta alguns sintomas, tais como perda de peso, alteração visual, fome e sede frequentes, vontade constante de urinar (inclusive várias vezes à noite), náuseas, sensação de choques ou dor nas pernas e fraqueza.'
    const obesidadeText = 'A obesidade é hoje considerada uma doença crônica, sendo necessário o tratamento adequado visando controle de peso (perda de peso 5-10%) ou remissão (normalização do peso).'
    const tireoideText = 'A tireóide é uma glândula em formato de borboleta que fica na região cervical. Ela pode sofrer de doenças e apresentar sintomas como formação de nódulos e inflamações, levando a aumentos ou diminuições de hormônios.'
    const osteoporoseText = 'Doença que costuma acometer mulheres com mais de 60 anos, e homens com mais de 70 anos, deve ser rastreada quando indicada para tratamento correto e precoce para evitar fraturas e suas consequências, como dor crônicas e limitação de movimento.'
    const dislipidemiaText = 'Alteração de colesterol e / ou triglicérides, sempre avaliado individualmente de acordo com o risco cardiovascular do paciente e a meta para tratamento.'
    const outrosText = 'Distúrbios em menstruação feminina ou no desenvolvimento de crianças acima de 7(sete) anos(crescimento deficiente ou puberdade precoce ou atrasada).'

    const rolarParaSecao = (secaoId) => {
        const elementoSecao = document.getElementById(secaoId)
        if (elementoSecao) elementoSecao.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <section id='area-atuacao'>
            <h1>Especializações</h1>
            <p className="lh-base fs-2 text-justify text-break">Atualmente, atendo genericamente todo tipo de situação relacionada com sistema endrocrinológico, sendo certo que meu diagnóstico/tratamento concentra esforços nas seguintes áreas de atuação:</p>
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <CardsAtuacao
                            imagem={diabetes}
                            areaAtuacao='Diabetes'
                            texto={diabetesText} />
                    </div>
                    <div class="col-sm">
                        <CardsAtuacao
                            imagem={obesidade}
                            areaAtuacao='Obesidade'
                            texto={obesidadeText} />
                    </div>

                    <div class="w-100 d-none d-md-block"></div>

                    <div class="col-sm">
                        <CardsAtuacao
                            imagem={tireoide}
                            areaAtuacao='Tireoide'
                            texto={tireoideText} />
                    </div>
                    <div class="col-sm">
                        <CardsAtuacao
                            imagem={osteoporose}
                            areaAtuacao='Osteoporose'
                            texto={osteoporoseText} />
                    </div>

                    <div class="w-100 d-none d-md-block"></div>

                    <div class="col-sm">
                        <CardsAtuacao
                            imagem={dislipidemia}
                            areaAtuacao='Dislipidemia'
                            texto={dislipidemiaText} />
                    </div>
                    <div class="col-sm">
                        <CardsAtuacao
                            imagem={outros}
                            areaAtuacao='Outros'
                            texto={outrosText} />
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
            <h1>Métodos de Atendimento</h1>
            <p className="lh-base fs-2 text-justify text-break">Em consultório particular, exerço atendimento com foco em <em>slow medicine</em>, buscando compreender o contexto do paciente e avaliar uma série de fatores que podem influenciar ou desencadear sintomas ou doenças. Nesse momento, considero exames diagnósticos e hábitos alimentares e comportamentais do paciente, bem como realizo exame de bioimpedância <em>gratuitamente</em> na consulta. Ao final do atendimento, será abordado um plano terapêutico com medicamentos e outras intervenções que sejam necessárias com metas claras de ação.</p>
            <p className="lh-base fs-2 text-justify text-break">Além disso, todos os pacientes particulares têm direito a um retorno em até 60 dias, assim como primeira consulta online com a nutricionista da equipe (Maria Tereza Vaz de Castro Lemos - CRN 9-22630).</p>
            <p>&nbsp;</p>
            <h1>Avaliação</h1>
            <p className="lh-base fs-2 text-justify text-break">Caso já tenha sido diagnosticado, ou suspeita de alguma doença endrocrinológia, estou à disposição para uma avaliação. Basta agendar uma <button className="btn-agendar" style={{border: 'none', borderRadius: '10px', padding: '5px'}} onClick={() => rolarParaSecao('agendamentos-parallax')}>consulta</button>!</p>
            <p>&nbsp;</p>
        </section>
    )
}

export default AreaAtuacao