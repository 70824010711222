import React from "react"

const Rodape = () => (
    <footer id='footer'>
        <div className="container text-center">
            <div className="row">
                <div className="col-12">
                   &copy; 2023. Dra. Isabela Augusta de Castro Lemos | CRM-MG 64742. Todos os Direitos Reservados.
                </div>
            </div>
        </div>
    </footer>
)

export default Rodape