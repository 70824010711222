import { Parallax } from "react-parallax"
import Exame from '../img/exameapalpacao.png'

const insideStyles = {
    borderRadius:'50px',
    background: "#333",
    margin: '20px',
    padding: '10',
    position: "absolute",
    width: '400px',
    bottom: '0',
    right: '0',
};

const AreaAtuacaoParallax = () => (
    <Parallax bgImageStyle={{ maxWidht: '80%' }} bgImage={Exame} strength={200}>
        <div id='area-atuacao-parallax' style={{ height: '40vh' }}>
            <div style={insideStyles}>
                <div className="text-box-n1">Especializações</div>
            </div>
        </div>
    </Parallax>
)

export default AreaAtuacaoParallax
