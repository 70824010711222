import React from "react"

const tituloAreaAtuacao = {
    color: 'var(--t1-8)',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontWeight: 800,
    textAlign: 'center',
}

const CardsAtuacao = ({ imagem, areaAtuacao, texto }) => {
    return (
        <div className="container m-2">
            <div className="row ">
                <div className="col text-center">
                    <img src={imagem} width='40px' height='40px' alt={areaAtuacao}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p style={tituloAreaAtuacao}>{areaAtuacao}</p>
                    <h5 className="text-justify">{texto}</h5>
                </div>
            </div>
        </div>
    )
}

export default CardsAtuacao