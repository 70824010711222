import React from "react"

const Apresentacao = () => {
    return (
        <section id='apresentacao'>
            <h1 className="text-upper">Apresentação</h1>
            <p className="lh-base fs-2 text-justify text-break">Médica formada pela Faculdade de Ciências Médicas de Minas Gerais, com residência em Clínica Médica pelo Hospital João XXIII e Endocrinologia e Metabologia pela Santa Casa de Belo Horizonte. É membro titulada da Sociedade Brasileira de Endocrinologia e Metabologia, bem como atual coordenadora e fundadora do Departamento de Endocrinologia e Metabologia da Associação Médica de Conselheiro Lafaiete.</p>
            <p>&nbsp;</p>
            <h1 className="text-upper">Cursos e Congressos</h1>
            <p className="lh-base fs-2 text-justify text-break">Para aprimoramento profissional, participou dos seguintes cursos e congressos:</p>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <ul class="list-group">
                        <li class="list-group-item">XXIV Congresso da Sociedade Brasileira de Diabetes, realizado em 25 a 28 de Outubro de 2023.</li>
                        <li class="list-group-item">Curso LPH SUMMIT - Ciências e Medicina do Exercício e do Esporte, realizado nos dias 8,9,15,16 e 22 de agosto 2023.</li>
                        <li class="list-group-item">Curso ETED HÍBRIDO 2023 — Encontro de Tecnologia em Endocrinologia e Diabetes, realizado nos dias 2 e 3 de Junho 2023.</li>
                        <li class="list-group-item">19º Congresso Mineiro de Endocrinologia e Metabologia, realizado no período de 12 a 14 de novembro 2022.</li>
                        <li class="list-group-item">Curso 51º Encontro Anual IEDE, realizado nos dias 4 e 5 de Novembro 2022.</li>
                        <li class="list-group-item">Curso ETED - Encontro de Tecnologia em Endocrinologia e Diabetes, realizado em 24 e 25 de junho 2022.</li>
                        <li class="list-group-item">Curso SEND ON-LINE 2022 — Seminário de Endocrinologia, Nutrologia e Diabetes, realizado nos dias 3, 4, 10, 11, 17, 18, 20, 21, 22 e 24 de maio de 2022.</li>
                        <li class="list-group-item">Curso DIABETES RIO 2022, realizado em 18 e 19 de março 2022.</li>
                        <li class="list-group-item">SIEEX SBEM 2022 — 4º Simpósio Integrado de Endocrinologia do Exercício, realizado de 25 a 27 de março 2022.</li>
                        <li class="list-group-item">SIEEX SBEM 2021 | Simpósio Integrado de Endocrinologia do Exercício realizado nos dias 12 e 13 de março de 2021.</li>
                        <li class="list-group-item">34º Congresso Brasileiro de Endocrinologia e Metabologia (e-CBEM), realizado no período de 28 ade novembro a 05 de Dezembro de 2020.</li>
                        <li class="list-group-item">Curso Advanced Medical Care 2023, realizado nos dias 22 e 23 de setembro 2023.</li>
                        <li class="list-group-item">7o Encontro Brasileiro de Endocrinologia Pediátrica – EBEP 2020, realizado online nos dias 19 a 21 de outubro de 2020.</li>
                        <li class="list-group-item">CAEFA 2019 - Curso Avançado de Endocrinologia Feminina e Andrologia, realizado nos dias 22 e 23 de novembro de 2019.</li>
                        <li class="list-group-item">Congresso Brasileiro de Atualização em Endocrinologia e Metabologia – CBAEM 2019 realizado em 21 a 24 de Agosto de 2019.</li>
                        <li class="list-group-item">33º Congresso Brasileiro de Endocrinologia e Metabologia, realizado no período de 7 a 11 de Agosto de 2018.</li>
                    </ul>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    )
}

export default Apresentacao