import React from 'react'
import {
   createBrowserRouter,
   createRoutesFromElements,
   Route
} from 'react-router-dom'

import App from '../pages/page'
import ErrorPage from '../pages/error'

const elements = createRoutesFromElements(
    <Route path='/' element={<App />} errorElement={<ErrorPage />}>
       <Route path='*' element={<ErrorPage />} />
    </Route>
 )
 
 const router = createBrowserRouter(elements)
 
 export default router