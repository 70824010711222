import logo from "../img/logo.png"

const Navbar = () => {

    const rolarParaSecao = (secaoId) => {
        const elementoSecao = document.getElementById(secaoId)
        if (elementoSecao) elementoSecao.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <nav className='navbar fixed-top navbar-component bg-custom'>
            <div className="container">

                <div className="col-3 text-left">
                    <button
                        className="btn btn-agendar btn-sm"
                        onClick={() => rolarParaSecao('agendamentos')}>
                        AGENDAR CONSULTA
                    </button>
                </div>

                <div className="col-7 text-center">
                    <div className="container-fluid justify-content-end">
                        <div className="row">
                            <div className="col-lg-3">
                                <span
                                    className="item"
                                    onClick={() => rolarParaSecao('apresentacao-parallax')}>
                                    Apresentação
                                </span>
                            </div>
                            <div className="col-lg-3">
                                <span
                                    className="item"
                                    onClick={() => rolarParaSecao('quando-procurar-parallax')}>
                                    Atendimento
                                </span>
                            </div>


                            <div className="col-lg-3">
                                <span
                                    className="item"
                                    onClick={() => rolarParaSecao('area-atuacao-parallax')}>
                                    Especializações
                                </span>
                            </div>
                            <div className="col-lg-3">
                                <span
                                    className="item"
                                    onClick={() => rolarParaSecao('depoimentos-parallax')}>
                                    Depoimentos
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 text-left">
                    <img src={logo} className="img-fluid" alt="Dra. Isabela Lemos" />
                </div>
            </div>
        </nav>

    )
}

export default Navbar