import React from 'react'
import { Link, useRouteError } from 'react-router-dom'

function ErrorPage() {
    const error = useRouteError()
    console.error(error)

    return (
        <section id="paginaErro" className="boxContent">
            <h1>Default: {error.statusText || error.message}</h1>
            <Link href="/">GO BACK</Link>
        </section>
    )
}

export default ErrorPage