import { Parallax } from "react-parallax"
import Quando from '../img/estetoscopio.png'

const insideStyles = {
    borderRadius:'50px',
    background: "#333",
    margin: '20px',
    padding: '10',
    position: "absolute",
    width: '400px',
    bottom: '0',
    right: '0',
};

const QuandoProcurarParallax = ()=> (
    <Parallax bgImageStyle={{ maxWidht: '80%' }} bgImage={Quando} strength={200}>
        <div id='quando-procurar-parallax' style={{ height: '40vh' }}>
            <div style={insideStyles}>
                <div className="text-box-n1">Atendimento</div>
            </div>
        </div>
    </Parallax>
)

export default QuandoProcurarParallax