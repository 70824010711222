import { Parallax } from "react-parallax"
import Agendamentos from '../img/agendamento.png'

const insideStyles = {
    borderRadius:'50px',
    background: "#333",
    margin: '50px',
    padding: '10',
    position: "absolute",
    width: '400',
    bottom: '0',
    right: '0',
};

const AgendamentosParallax = () => (

    <Parallax bgImageStyle={{ maxWidht: '80%' }} bgImage={Agendamentos} strength={200}>
        <div id='agendamentos-parallax' style={{ height: '40vh' }}>
            <div style={insideStyles}>
                <div className="text-box-n1">Agendamento de Consultas</div>
            </div>
        </div>
    </Parallax>
)

export default AgendamentosParallax