import React from 'react';

const WhatsAppButton = () => {
    const telefone = '553197089291'
    const mensagem = 'Oi, tudo bem? Gostaria de agendar uma consulta!'
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${telefone}&text=${encodeURIComponent(mensagem)}`;

    const handleButtonClick = () => {
        window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='submit' onClick={handleButtonClick}>
            Agendamento (WhatsApp)
        </div>
    );
};

export default WhatsAppButton