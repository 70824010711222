import Navbar from '../components/Navbar';

import ApresentacaoParallax from '../components/Apresentacao'
import Apresentacao from './apresentacao';

import QuandoProcurarParallax from '../components/QuandoProcurar'
import QuandoProcurar from './quandoprocurar';

import AreaAtuacaoParallax from '../components/AreaAtuacao';
import AreaAtuacao from './areaatuacao';

import DepoimentosParallax from '../components/Depoimentos';
import Depoimentos from './depoimentos';

import AgendamentosParallax from '../components/Agendamentos';
import Agendamentos from './agendamentos';

import Rodape from '../components/Rodape';

import '../scss/style.css';

function App() {
  return (
    <div id="app">

      <Navbar />

      <ApresentacaoParallax />
      <Apresentacao />

      <QuandoProcurarParallax />
      <QuandoProcurar />

      <AreaAtuacaoParallax />
      <AreaAtuacao />

      <DepoimentosParallax />
      <Depoimentos />

      <AgendamentosParallax />
      <Agendamentos />

      <Rodape />
    </div>
  );
}

export default App;
